<div class="container-fluid" style="min-height: 110vh;" *ngIf="user">


    <div class="row">
        <div class="col-12">
            <div class="preview-box">
                <div class="preview-box-title bg-grey p-4 pb-0">
                    Payment History
                </div>
                <div class="preview-box-content p-4">
                    <div class="data-container">
                        <app-payment-search class="mt-4" [params]="paymentParams"></app-payment-search>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
