<div class="table-responsive">
    <table class="table table-condensed">
        <thead class="thead-white">

        <tr>
            <th></th>
            <th *ngIf="showCustomer">Customer</th>
            <th>Order No</th>
            <th>Created</th>
            <th>Product</th>
            <th>Status</th>
            <th *ngIf="showSupplier">Supplier</th>
            <th *ngIf="(user$ | async).accountType === 'supplier'">Shipping</th>
            <th>Due Date</th>
            <th>Actions</th>
        </tr>
        <tr>
            <th></th>
            <th *ngIf="showCustomer"><input type="text" class="form-control" [(ngModel)]="params.customer" (ngModelChange)="filterSearch()" /></th>
            <th><input type="text" class="form-control" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" /></th>
            <th><input type="text" class="form-control" [(ngModel)]="params.orderId" (ngModelChange)="filterSearch()" /></th>
            <th><input type="text" class="form-control" [(ngModel)]="params.invoiceId" (ngModelChange)="filterSearch()" /></th>
            <th><input type="text" class="form-control" [(ngModel)]="params.status" (ngModelChange)="filterSearch()" /></th>
            <th *ngIf="showSupplier"><input type="text" class="form-control" [(ngModel)]="params.supplier" (ngModelChange)="filterSearch()" /></th>
            <th *ngIf="(user$ | async).accountType === 'supplier'"><input type="text" class="form-control" [(ngModel)]="params.shippingStatus" (ngModelChange)="filterSearch()" /></th>
            <th><input type="text" class="form-control" [(ngModel)]="params.dueDate" (ngModelChange)="filterSearch()" /></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr class="underline" *ngFor="let s of orders">
            <td>
                <a *ngIf="s.updateRequested && (user$ | async).accountType === 'supplier'" [routerLink]="['/account', 'orders', s.orderId]">
                    <i class="fa fa-exclamation-triangle text-danger"></i>
                </a>
            </td>
            <td *ngIf="showCustomer">{{s.contactFirstname}} {{s.contactSurname}}</td>
            <td>{{s.publicId}}</td>
            <td>{{s.createdAt | date: 'dd MMM yyyy'}}</td>
            <td>
                {{s.productName}}
                <span *ngIf="s.isSample && (user$ | async).accountType !== 'supplier'">(Sample)</span>
            </td>
            <td>{{s.status.name}}</td>
            <td *ngIf="showSupplier">
            <span *ngFor="let supplier of s.suppliers; let first = first">
                <span *ngIf="!first">, </span>{{supplier.name}}
            </span>
            </td>
            <td *ngIf="(user$ | async).accountType === 'supplier'">Pending</td>
            <td>{{s.dueDate | date: 'dd MMM yyyy'}}</td>
            <td><a [routerLink]="['/account', 'orders', s.orderId]">
                <i class="fa fa-edit"></i>
            </a> </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="no-results" *ngIf="!orders.length">
    <p>No orders found</p>
</div>

<app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>

