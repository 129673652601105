import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-growler',
    templateUrl: './growler.component.html',
    styleUrls: ['./growler.component.scss']
})
export class GrowlerComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
