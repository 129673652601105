<div class="container-fluid" style="min-height: 110vh;" *ngIf="user">

    <div class="preview-box">
        <div class="preview-box-title bg-grey p-4 pb-0">
            Your Users
        </div>
        <div class="preview-box-content p-4">

            <app-user-search [showCompany]="false" [company]="user.company"></app-user-search>
        </div>
    </div>

</div>

<app-user-edit *ngIf="(overlays$ | async).userEdit"></app-user-edit>

