import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import {environment} from "../../environments/environment";

@Injectable()
export class AppNameInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        let headers = request.headers;

        if (environment.emulateSslForLocalDev) {
            headers = headers.set('x-forwarded-proto', 'https');
        }
        headers = headers.set("app-name", "courier");
        const authReq = request.clone({ headers, withCredentials: true });

        return next.handle(authReq);
    }
}
