<div class="container-fluid bg-grey">
    <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 text-center">
            <p class="main-heading mt-5 mb-50">Password Reset</p>
            <p>Your password has been successfully updated. You can now login</p>
            <div class="text-center mt-60 mb-60">
                <button [routerLink]="['/login']"
                    type="button"
                    class="btn btn-round btn-primary">
                    Go to sign-in
                </button>
            </div>

        </div>
    </div>

</div>


