
<div class="d-flex justify-content-between align-items-center header-padding">
    <div>
        <a class="navbar-brand mx-auto" [routerLink]="['/']">
            <img src="/assets/images/ys-logo-text.png" style="height: 45px" />
        </a>
    </div>
    <div class="flex-grow-1 d-flex text-right justify-content-end align-items-center" *ngIf="!(user$ | async)">
        <button class="btn btn-trans btn-round btn-login" (click)="login()">
            Sign in
        </button>
        <button class="btn btn-trans btn-round btn-account" style="margin-right: 27px;" (click)="register()">
            Create account
        </button>
    </div>

    <div class="flex-grow-1" *ngIf="(user$ | async)">
        
        <div class="account-dropdown d-flex justify-content-end align-items-center header-right">
            <ul class="d-flex justify-content-end align-items-center mb-0">
            <li class="nav-item position-relative me-4">
                <a class="header-sub-item-icon" (click)="toggleNotifications()">
                    
                    <svg-icon
                            src="assets/images/icon-bell.svg"
                            [class]="'hover'"
                            [svgClass]="'nav-item-icon'"></svg-icon>
                    <div class="badge-count rounded" *ngIf="(notifications$ | async).unread > 0">{{(notifications$ | async).unread}}</div>
                </a>
            </li>
            <li class="nav-item position-relative me-4">
                <a class="header-sub-item-icon" [routerLink]="['/account', 'mail']" *ngIf="(user$ | async)">
                    <div class="badge-count rounded" *ngIf="(chatStore$ | async).unread">{{(chatStore$ | async).unread}}</div>
                    <svg-icon
                            src="assets/images/icon-chat.svg"
                            [class]="'hover'"
                            [svgClass]="'nav-item-icon'"></svg-icon>
                </a>
            </li>
            <li class="nav-item position-relative me-4" *ngIf="!(user$ | async)">
                <a class="header-sub-item-icon" (click)="toggleChat()" >
                    <div class="badge-count rounded" *ngIf="(chatStore$ | async).unread">{{(chatStore$ | async).unread}}</div>
                    <svg-icon
                            src="assets/images/icon-chat.svg"
                            [class]="'hover'"
                            [svgClass]="'nav-item-icon'"></svg-icon>
                    
                </a>
            </li>
            <li class="nav-item position-relative me-4">
                <a appDropdown href="javascript:void(0)" class="header-sub-item-icon dropdown mr-0">
                    <svg-icon src="assets/images/icon-user.svg" class="me-2"></svg-icon>
                    <span class="me-2">{{(user$ | async).contact?.firstname}} {{(user$ | async).contact?.surname}}</span>
                    <!-- <svg-icon src="assets/images/icon-chevron-down.svg"></svg-icon> -->
                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-user shadow-sm px-4 py-3">
                        <a href="https://www.yorkstonesupplies.co.uk" class="dropdown-item">
                            Back to homepage
                        </a>
                     <!---  <div class="dropdown-divider mt-0 mb-2"></div>
                        <a  class="dropdown-item fw-bold small px-0 mb-2 text-primary custom-color"
                            [routerLink]="['/account']">
                            My Account
                        </a>-->
                        <div class="dropdown-divider mt-0 mb-2"></div>
                        <a (click)="logoutConfirmation()" class="dropdown-item">
                                <!-- <i class="fas fa-sign-out-alt"></i> -->
                                 Sign out
                        </a>
                       
                    </div>
                </a>
            </li>
            <li class="nav-item position-relative me-0">
                <a (click)="logoutConfirmation()" class="sign-out">
                    <!-- <i class="fas fa-sign-out-alt"></i> -->
                    <!-- <img src="assets/images/sign-out.png" class="me-2 signout-icon"> --> 
                    <svg-icon src="assets/images/signout.svg" class="me-2 signout-icon"></svg-icon>
                    <span>Sign out</span>
                </a>
            </li>
        </ul>

        </div>
        
        <!-- <a class="header-sub-item" [routerLink]="['account']">
            <i class="fa fa-user"></i> Your account
        </a> -->
        <!-- <a class="header-sub-item" (click)="logoutConfirmation()">
            Sign out
        </a>
        <a class="header-sub-item-icon" (click)="toggleNotifications()">
            <i class="far fa-bell fa-fw" style="font-size: 17px"></i>
            <div class="badge-count rounded" *ngIf="(notifications$ | async).unread > 0">{{(notifications$ | async).unread}}</div>
        </a>
        <a class="header-sub-item-icon" [routerLink]="['/account', 'mail']" *ngIf="(user$ | async)">
            <i class="far fa-comments fa-fw"></i>
            <div class="badge-count rounded" *ngIf="(chatStore$ | async).unread">{{(chatStore$ | async).unread}}</div>
        </a>
        <a class="header-sub-item-icon" (click)="toggleChat()"  *ngIf="!(user$ | async)">
            <i class="far fa-comments fa-fw"></i>
            <div class="badge-count rounded" *ngIf="(chatStore$ | async).unread">{{(chatStore$ | async).unread}}</div>
        </a> -->
    </div>
</div>

<app-confirmation
    *ngIf="confirmationData.open"
    [data]="confirmationData"
    (complete)="confirmationComplete($event)"
></app-confirmation>


