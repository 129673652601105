

<form [formGroup]="form"
                  class="col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4"
                  autocomplete="off"
                  novalidate
                  [autocomplete]="'off'"
                  (ngSubmit)="onSubmit()">
                  <div class="card w-100">
                      <div class="card-body d-flex flex-column shadow-lg p-xl-5">
               
                        <div class="d-flex flex-column align-items-center">
                            <svg-icon src="assets/images/ys-logo-icon-reversed.svg"
                                      class="mb-2"
                                      [svgClass]="'logo--icon'"></svg-icon>
                            <h1 class="fs-2 fw-bold mb-4 mb-xl-5 text-center">Enter your account details to log-in</h1>
                        </div>
                        <div class="mb-3">
                            <input
                            type="email"
                            autocomplete="new-password"
                            class="form-control"
                            [ngClass]="{'is-invalid' : form.controls.username.invalid && form.controls.username.touched}"
                            formControlName="username"
                            placeholder="Email">
                <div class="invalid-feedback">Enter a valid email</div>
                        </div>
                        <div class="mb-3">
                            <input
                                type="password"
                                class="form-control"
                                autocomplete="new-password"
                                [ngClass]="{'is-invalid' : form.controls.password.invalid && form.controls.password.touched}"
                                formControlName="password"
                                placeholder="Password">
                        <div class="invalid-feedback" *ngIf="passwordValidated">Password must be 8 characters or more</div>
                        <div class="invalid-feedback" *ngIf="!passwordValidated">Email / password combination incorrect
                        </div>
                        </div>
                        <div class="d-flex justify-content-between">

                            <div class="form-check" (click)="toggleExtendedExpiration()">
                                <input id="stayLoggedIn" class="form-check-input" type="checkbox" [checked]="user.extendedExpiration">
                                <label class="form-check-label" for="stayLoggedIn">
                                    Stay logged in
                                </label>
                            </div>
                            <a (click)="triggerForgottenPassword()" class="text-muted hover">Forgotten password</a>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button [disabled]="form.invalid" class="btn btn-primary mt-3" type="submit" tabindex="3">Log-in
                            </button>
                        </div>
                    </div>
                </div>
            </form>
