<section class="registration bg-grey">
    <div class="form-container" *ngIf="user">
        <h1 class="text-center">Additional details</h1>
        <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
            <p>
                Thanks for registering. Please enter your business address to allow us to verify your account.
            </p>

            <div class="form-group">
                <input
                    type="text"
                    [ngClass]="{'is-invalid' : form.controls['add1'].invalid && form.controls['add1'].touched}"
                    class="form-control"
                    formControlName="add1"
                    placeholder="Address line 1">
                    <div class="invalid-feedback">Address line 1 required</div>
                </div>
            <div class="form-group">
                <input type="text" class="form-control" formControlName="add2" placeholder="Address line 2">
            </div>

            <div class="form-group">
                <input type="text" class="form-control" formControlName="add3" placeholder="Address line 3">
            </div>
            <div class="form-group">
                <input
                    type="text"
                    class="form-control"
                    [ngClass]="{'is-invalid' : form.controls['town'].invalid && form.controls['town'].touched}"
                    formControlName="town"
                    placeholder="Town / city">
                <div class="invalid-feedback">Town or city required</div>
            </div>
            <div class="form-group">
                <input
                    type="text"
                    [ngClass]="{'is-invalid' : form.controls['county'].invalid && form.controls['county'].touched}"
                    class="form-control"
                    formControlName="county"
                    placeholder="County">
                <div class="invalid-feedback">County required</div>
            </div>
            <div class="form-group">
                <input
                    type="text"
                    [ngClass]="{'is-invalid' : form.controls['postcode'].invalid && form.controls['postcode'].touched}"
                    class="form-control col-12 col-sm-6"
                    formControlName="postcode"
                    placeholder="Postcode">
                <div class="invalid-feedback">Postcode required</div>
            </div>

            <div class="mt-4 text-right">
                <button [disabled]="!form.valid" type="submit" class="btn btn-round btn-primary">Add Address</button>
            </div>

        </form>
    </div>
</section>
