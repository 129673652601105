
<div class="form--min-height bg-secondary custom-login-form">
    <div class="container h-100 py-3 py-md-5 justify-content-center align-items-center h-100">

          
                        <app-login-form (authenticated)="authenticated($event)" class="row justify-content-center align-items-center h-100 d-flex"></app-login-form>
                

    </div>
</div>
<!-- <app-contact-cta></app-contact-cta> -->


