<div class="container-fluid bg-grey">
    <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 text-center">
            <p class="main-heading mt-5 mb-50">Forgotten Password</p>
            <p>Instructions on how to reset your password have been sent to your registered email address:
            {{confirmationEmail}}</p>
                <div class="row mt-60 mb-60">
                    <div class="col-12 col-sm-6">
                        <button
                            type="button"
                            (click)="signIn()"
                            class="btn btn-round btn-primary">
                            Go to sign-in
                        </button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <button
                            type="button"
                            (click)="home()"
                            class="btn btn-round btn-secondary">
                            Homepage
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>

