import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NotificationService} from '../../services/notification.service';
import { Store } from '../../store';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    params: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private store: Store,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams.resetLogin) {
            this.notificationService.stopListening();
            this.authService.logout().subscribe(() => {
                this.authService.clearAuthTimeout();
            });
        }
        if (this.store.selectForLocal('user')) {
            this.router.navigate(['/account']);
        }
    }

    authenticated(e) {
        this.router.navigate(['/account']);
    }
}
