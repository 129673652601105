import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import {ToolsService} from '../services/tools.service';
import {CookieService} from '../services/cookie.service';
import {Router} from '@angular/router';

@Injectable()
export class ErrorRequestInterceptor implements HttpInterceptor {
    constructor(
        private toolsService: ToolsService,
        private cookieService: CookieService,
        private router: Router
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.error.code === "AUTH-0002") {
                    this.toolsService.logoutSessionId();
                    this.cookieService.remove();

                    this.router.navigate(
                        ['/'],
                        {
                            queryParams: {
                                resetLogin: true
                            }
                        }
                    )
                    return of({});
                }
                return throwError(() => error);
            })
        );
    }
}
