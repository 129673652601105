<div class="p-4" *ngIf="rewardsSummary">
    <div class="row">
        <div class="col-xl-4 text-center">
            <h3 class="dashboard-reward-points-title">Current Points</h3>
            <h1 class="dashboard-reward-points-value">{{rewardsSummary.points}} points</h1>
        </div>
        <div class="col-xl-4 text-center">
            <h3 class="dashboard-reward-points-title">Current Discount</h3>
            <h1 class="dashboard-reward-points-value">{{rewardsSummary.currentPercentage}}%</h1>
        </div>
        <div class="col-xl-4 text-center">
            <h3 class="dashboard-reward-points-title" *ngIf="!rewardsSummary.currentLevel">Points until first reward</h3>
            <h3 class="dashboard-reward-points-title" *ngIf="rewardsSummary.currentLevel">Points until next reward</h3>
            <h1 class="dashboard-reward-points-value">{{rewardsSummary.nextLevel - rewardsSummary.points}} points</h1>
        </div>
    </div>

    <div class="progress" style="position: relative; margin-top: 120px; margin-bottom: 120px">
        <div
            *ngFor="let pr of rewardsSummary.pointsRange"
            [style.left]="pr.perc + '%'"
            style="position: absolute; top: 0; width: 1px; height: 30px; background-color: white"></div>
        <div
            style="position: absolute; top: 35px; left:0; width: 60px; text-align: left; height: 30px;">0</div>
        <div
            *ngFor="let pr of rewardsSummary.pointsRange"
            [style.left]="(pr.perc - 2) + '%'"
            style="position: absolute; top: 35px; width: 60px; text-align: center; height: 20px;">
            <span class="dashboard-rewards-slider-value">{{pr.points}}</span>
        </div>
        <div
            *ngFor="let pr of rewardsSummary.pointsRange"
            [style.left]="(pr.perc - 2) + '%'"
            style="position: absolute; top: -55px; width: 60px; text-align: center; height: 20px;">
            <span class="dashboard-rewards-slider-value" *ngIf="pr.gift.type === 'perc'">{{pr.gift.value}}%<br />Discount</span>
            <span class="dashboard-rewards-slider-value" *ngIf="pr.gift.type === 'currency'">{{pr.gift.value | currency:'GBP' : 'symbol' : '1.0-0'}}<br />Gift</span>
        </div>

        <div
            class="progress-bar progress-bar-animated progress-bar-success"
            role="progressbar"
            [style.width]="rewardsSummary.percOne + '%'"
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"></div>
            <div
                class="progress-bar progress-bar-animated progress-bar-warning"
                role="progressbar"
                [style.width]="rewardsSummary.percTwo + '%'"
                aria-valuenow="20"
                aria-valuemin="0"
                aria-valuemax="100"></div>
        <!--        <div class="progress-bar progress-bar-animated progress-bar-danger" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>-->
    </div>

    <div class="row mt-30">
        <ng-container *ngFor="let t of rewardTriggers">
            <div class="col-md-4"
                 *ngIf="(!user.company && t.type !== 'casestudy') || (user.company)">
                <div class="reward-challenge-holder">
                    <a *ngIf="t.externalUrl" [href]="t.externalUrl" target="_blank">
                        <img *ngIf="t.imageUrl" [src]="'/assets/images/' + t.imageUrl" />
                    </a>
                    <div *ngIf="!t.externalUrl">
                        <i *ngIf="!t.imageUrl && t.type === 'referral'" class="fa fa-user-friends" style="font-size: 74px"></i>
                        <a *ngIf="!t.imageUrl && t.type === 'casestudy'" [routerLink]="['/account', 'casestudies']">
                            <i class="fa fa-images" style="font-size: 74px"></i>
                        </a>
                    </div>
                    <h1>{{t.points}} points</h1>
                    <h2>{{t.description}}</h2>

                    <ng-container *ngIf="t.type === 'review'">
                        <ng-container *ngIf="t.triggered">
                            <div class="reward-challenge-icon" *ngIf="t.triggered.confirmed === 1">
                                <i class="far fa-fw fa-check-circle"></i>
                            </div>
                            <div class="reward-challenge-status" *ngIf="t.triggered.confirmed === 1">
                                Challenge complete. Points have been added to your rewards ({{t.triggered.createdAt | date: 'dd MMM yyyy'}})
                            </div>

                            <div class="reward-challenge-icon" *ngIf="t.triggered.confirmed === 0">
                                <i class="far fa-hourglass"></i>
                            </div>
                            <div class="reward-challenge-status" *ngIf="t.triggered.confirmed === 0">
                                Review submitted {{t.triggered.createdAt | date: 'dd MMM yyyy'}}
                                <br />
                                Awaiting confirmation
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!t.triggered">
                            <button type="button" (click)="trigger(t)" class="btn btn-round btn-slim btn-secondary-reverse">
                                Review now
                            </button>
                        </ng-container>

                    </ng-container>


                    <ng-container *ngIf="t.type === 'referral'">
                        <ng-container *ngIf="t.triggered.total > 0">
                            <button type="button" (click)="emailInvites()" class="btn btn-round btn-slim btn-yellow">
                                {{t.triggered.total}} referred - Share again?
                            </button>

                        </ng-container>
                        <ng-container *ngIf="t.triggered.total === 0">
                            <button type="button" (click)="emailInvites()" class="btn btn-round btn-slim btn-secondary-reverse">
                                Share your unique code
                            </button>
                        </ng-container>

                    </ng-container>

                </div>
            </div>

        </ng-container>
    </div>

</div>

<app-reward-email-invite *ngIf="(overlays$ | async).rewardEmailInvite" (refreshData)="getRewardTriggers()"></app-reward-email-invite>
