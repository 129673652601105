<div class="container-fluid bg-grey">
    <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 text-center">
            <p class="main-heading mt-5 mb-60">Forgotten Password</p>
            <p>Don't worry, if you've forgotten your password. Enter your registered email address and we'll send you instructions on how to reset it.</p>
            <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
                <div class="form-group mt-60">
                    <input
                        type="email"
                        class="form-control"
                        [ngClass]="{'is-invalid' : form.controls.email.invalid && form.controls.email.dirty}"
                        formControlName="email"
                        placeholder="Email address">
                    <div class="invalid-feedback">Enter a valid email</div>
                </div>
                <div class="row mb-60">
                    <div class="col-12 col-sm-6">
                        <button
                            [disabled]="form.invalid"
                            type="submit"
                            class="btn btn-round btn-primary">
                            Reset password
                        </button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <button
                            type="button"
                            (click)="backToLogin()"
                            class="btn btn-round btn-secondary">
                            Back to sign-in
                        </button>
                    </div>
                </div>
                <div *ngIf="errorMessage" class="alert alert-danger">
                    <p>{{errorMessage}}</p>
                </div>
            </form>
        </div>
    </div>
</div>
