import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-rating-icons',
    templateUrl: './rating-icons.component.html',
    styleUrls: ['./rating-icons.component.scss']
})
export class RatingIconsComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
