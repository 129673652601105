import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '../store';
import {environment} from '../../environments/environment';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor {

    constructor(private store: Store) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const apiKey = '6bf9284a-ebf9-4afa-a185-4d2b9bb29e85';

        let headers = request.headers;

        headers = headers.set('domain', 'ys');
        headers = headers.set('api-key', apiKey);

        if (!headers.get('Content-Type')) {
            headers = headers.set('Content-Type', 'application/json');
        }
        if (environment.emulateSslForLocalDev) {
            headers = headers.set('x-forwarded-proto', 'https');
        }
        const authReq = request.clone({ headers, withCredentials: true });

        return next.handle(authReq);
    }
}
