<div class="sweet-overlay" tabindex="-1" style="opacity: 1.08;" [ngStyle]="{ 'display': _gone ? 'none' : 'block'}"></div>
<div class="sweet-alert shadow-sm p-4 p-xl-5"
     data-custom-class=""
     data-has-cancel-button="false"
     data-has-confirm-button="true"
     data-allow-outside-click="false"
     data-has-done-function="false"
     data-animation="pop"
     data-timer="null"
     [ngStyle]="{ 'margin-top': marginTop }"
     [ngClass]="{ 'showSweetAlert': _showModal && !_gone, 'hideSweetAlert': !_showModal && !_gone}"
>

  <div *ngIf="data.errors.length" class="sa-icon sa-error">
      <span class="sa-x-mark">
        <span class="sa-line sa-left"></span>
        <span class="sa-line sa-right"></span>
      </span>
  </div>

  <div *ngIf="data.notifications.length || data.statusUpdate" class="d-block sa-icon sa-success animate">
    <span class="sa-line sa-tip animateSuccessTip"></span>
    <span class="sa-line sa-long animateSuccessLong"></span>

    <div class="sa-placeholder"></div>
    <div class="sa-fix"></div>
  </div>

  <!-- simple text modal -->
  <ng-container *ngIf="data.simple !== null">
    <ul>
      <li class="ff-spectral text-muted">{{ data.simple.message }}</li>
    </ul>
    <div class="mt-4 d-flex justify-content-center align-items-center">
      <a class="btn {{ data.simple.okClass }} btn-thin" (click)="close()">{{ data.simple.okText }}</a>
    </div>
  </ng-container>

  <!-- Custom template contents -->
  <ng-container *ngIf="data.customAlert !== null"  style="display: block">
    <div #templateCont>
      <ng-container *ngTemplateOutlet="data.customAlert.template"></ng-container>
    </div>
    <div class="text-center" style="margin-top: 20px">
      <a class="btn btn-white ms-2" (click)="close()">{{ data.customAlert.cancelText }}</a>&nbsp;
      <a class="btn {{ data.customAlert.confirmClass }}"
         [ngClass]="{ 'is-invalid' : (data.customAlert.canConfirmCb && data.customAlert.canConfirmCb() !== true) }"
         (click)="confirm()"
      >{{ data.customAlert.confirmText }}</a>
    </div>
  </ng-container>

  <!-- Confirmation modal -->
  <ng-container *ngIf="data.confirmAction !== null">
    <h2>{{ data.confirmAction.title }}</h2>
    <p style="color: #7b7b7b">{{ data.confirmAction.message }}</p>
    <div class="text-center" style="margin-top: 20px">
      <a class="btn btn-white ms-2" (click)="close()">{{ data.confirmAction.cancelText }}</a>&nbsp;
      <a class="btn" [ngClass]="data.confirmAction.confirmClass" (click)="confirm()">{{ data.confirmAction.confirmText }}</a>
    </div>
  </ng-container>

  <ng-container *ngIf="data.notifications.length">
    <div class="ff-spectral fs-4 fw-bold text-center">Success</div>
    <hr class="bg-primary">
    <p class="ff-spectral lh-lg d-block text-center" *ngFor="let message of data.notifications" [innerHTML]="message"></p>
    <div class="mt-4 d-flex justify-content-center align-items-center">
      <button class="btn btn-primary btn-thin" tabindex="1" (click)="close()">
        Ok
      </button>
    </div>

  </ng-container>

  <ng-container *ngIf="data.errors.length">
    <div class="ff-spectral fs-4 fw-bold text-center">Error</div>
    <hr class="bg-primary">
    <p class="ff-spectral d-block text-center" *ngFor="let message of data.errors">{{message}}</p>
    <div class="mt-4 d-flex justify-content-center align-items-center">
      <button class="btn btn-primary btn-thin" tabindex="1" (click)="close()">
        Ok
      </button>
    </div>
  </ng-container>

</div>
