<div class="rating-holder">
    <div class="rating-box active">
        <i class="fa fa-star"></i>
    </div>
    <div class="rating-box active">
        <i class="fa fa-star"></i>
    </div>
    <div class="rating-box active">
        <i class="fa fa-star"></i>
    </div>
    <div class="rating-box">
        <i class="far fa-star"></i>
    </div>
    <div class="rating-box">
        <i class="far fa-star"></i>
    </div>
</div>
