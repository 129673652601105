<div class="container-fluid bg-light-grey" style="min-height: 110vh;">

    <div class="d-flex justify-content-between mt-0 mb-4 pt-5 pb-5 custom-board" *ngIf="(shipmentsBoard$ | async)">
        <div class="board-col-group" *ngFor="let g of (shipmentsBoard$ | async).groups">
            <h5 class="title">{{g.name}}</h5>
            <div class=" d-flex justify-content-between align-items-stretch">
                <div class="board-col" *ngFor="let c of g.cols">
                    <div class="title-color">
                        <div class="inner-title-color  d-flex justify-content-start align-items-center">
                            <div *ngIf="c.cards[0]">
                                <i class="fa fa-circle" [ngClass]="c.cards[0].bgCol"></i>
                            </div>
                            <h5 class="title">{{c.name}}</h5>
                        </div>
                    </div>
                    <div class="card-holder">
                        <div class="card px-0" *ngFor="let card of c.cards">
                            <div class="card-content" (click)="setPoNumber(card)">
                                <div class="row no-gutters mb-2 mx-0">
                                    <div class="col-6 px-0">
                                        <p class="mb-0 sm">ORD-{{card.orderIdPublic}}/{{card.lineId}}</p>
                                    </div>
                                    <div class="col-4">
                                    </div>
                                    <div class="col-2 text-right px-0">
                                        <a (click)="open(card)" type="button" class="btn btn-blank p-0" [popover]="popTemplate"
                                            [placement]="popoverPos" [adaptivePosition]="false" [outsideClick]="true">
                                            <i class="fa fa-ellipsis-h test"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="card-content">
                                <p class="mb-2">
                                    {{card.supplierName}}
                                </p>
                                <p class="mb-2 ">
                                    {{card.collectionPostcodeUsed}}
                                    <i class="fa fa-arrow-right"></i> {{card.deliveryPostcodeUsed}}
                                </p>
                                <div class="row mx-0">
                                    <div class="col-6 px-0">
                                        <p class="mb-2 md">{{card.pallets}} pallets</p>
                                    </div>
                                    <div class="col-6 text-right px-0">
                                        <p class="mb-2 md">{{card.serviceName}}</p>
                                    </div>
                                </div>
                            </div>

                            <hr />

                            <div class="card-content">
                                <div class="row no-gutters mb-2 mx-0" *ngIf="card.status.id !== 7">
                                    <div class="col-10 px-0">
                                        <p class="m-0 sm">
                                            <span class="duo">Due</span> {{card.dueDate | date: 'EEE dd MMM'}}
                                            ({{card.dueDays}} days)
                                        </p>
                                    </div>
                                    <div class="col-2 text-right px-0">
                                        <i class="fa fa-circle" [ngClass]="card.bgCol"></i>
                                    </div>
                                </div>
                            </div>
                            <ng-template #popTemplate>
                                <app-order-board-popup [card]="card" [col]="c" [params]="params"
                                    (orderModalRequested)="orderModalRequested($event)"
                                    (assignOrderLine)="triggerOrderAssign($event)"
                                     (showPoNumberModal)="showPoNumberModal($event)"
                                     (showInvNumberModal)="showInvNumberModal($event)"
                                    (swapSupplierRequested)="swapSupplierRequested($event)"
                                    (cancelOrderLine)="confirmCancellation($event)"
                                    (addTracking)="triggerAddingTracking($event)"
                                    (confirmOrderLine)="confirmOrderLine($event)"
                                    (updateRequested)="requestUpdate($event)" (back)="showBackModal($event)">
                                </app-order-board-popup>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-order-assign *ngIf="assigningOrder" [orderDetail]="orderDetail" [moveToConfirmation]="moveToConfirmation"
    [users]="admins" (close)="orderAssignComplete($event)"></app-order-assign>

<app-order-board-swap-supplier *ngIf="swapSupplier" [orderDetail]="orderDetail"
    (closeModal)="supplierSwapComplete($event)"></app-order-board-swap-supplier>

<app-add-po-number *ngIf="showPoNumber" (confirm)="savePoNumberModal($event)" (cancel)="showPoNumberModal($event)">
</app-add-po-number>

<app-add-inv-number
    *ngIf="showInvNumber" (confirm)="saveInvNumberModal($event)" (cancel)="showInvNumberModal($event)"></app-add-inv-number>

    <app-order-overview-modal
    *ngIf="viewOrderModal"
    [orderId]="orderId"
    [orderDetail]="orderDetail"
    (orderModalComplete)="orderModalComplete($event)"></app-order-overview-modal>

    
   

    <app-move-back-modal *ngIf="this.backConfirmModal.show" [currentStatus]="this.backConfirmModal.card.status" (complete)="this.backConfirmCompleted($event)"></app-move-back-modal>