import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-courier',
    templateUrl: './dashboard-courier.component.html',
    styleUrls: ['./dashboard-courier.component.scss']
})
export class DashboardCourierComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
