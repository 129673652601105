<div class="modal show">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Current Supplier: {{orderDetail.supplier.name}}</h5>
                <button (click)="cancel()" type="button" class="btn-close"></button>
            </div>
            <div class="modal-body">
                <div class="row mb-4">
                    <div class="col">
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                autocomplete="new-password"
                                class="form-control bordered"
                                [(ngModel)]="supplierParams.freeText"
                                [ngModelOptions]="{standalone: true}"
                                placeholder="Enter supplier name, number or contact">
                            <button type="button" (click)="searchSuppliers()" class="btn btn-primary btn-sm">
                                Find
                            </button>
                        </div>
                    </div>
                </div>
                <div class="no-results" *ngIf="!suppliers.length && searchSubmitted">
                    <p>No suppliers found</p>
                </div>
                <div class="table-responsive" *ngIf="suppliers.length">
                    <table class="table table-borderless align-middle">
                        <thead class="thead-white">
                        <tr>
                            <th class="ff-spectral  fs-5 fw-600 text-nowrap">Company name</th>
                            <th class="ff-spectral  fs-5 fw-600 text-nowrap">Town</th>
                            <th class="ff-spectral  fs-5 fw-600 text-nowrap">Telephone</th>
                            <th class="ff-spectral  fs-5 fw-600 text-nowrap">Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="toplined hover" *ngFor="let s of suppliers" (click)="confirmSubmit(s)">
                            <td>{{s.name}}</td>
                            <td><span *ngIf="s.address">{{s.address.town}}</span></td>
                            <td>{{s.landline}}</td>
                            <td>{{s.email}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="d-flex justify-content-between w-100">
                    <app-pagination *ngIf="supplierParams.pageArray.length" [params]="supplierParams" (update)="searchSuppliers()"></app-pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop show"></div>

<app-confirmation *ngIf="this.confirmationData.open" (complete)="confirmAction($event)" [data]="confirmationData"></app-confirmation>
