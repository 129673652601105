<div class="defaultAside aside-open slide-box-show">
    <a href="javascript:void(0)" class="closebtn" (click)="close()">&times;</a>

    <div class="page-header-banner">
        <h1>Assign Vehicle</h1>
    </div>

    <div class="p-4">
        <p>Select the vehicle you would like to assign to this shipment</p>
        <div class="list-group">
            <div
                *ngFor="let vehicle of vehicles"
                (click)="setVehicle(vehicle)"
                class="list-group-item mb-2">
                <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-1">{{vehicle.registration}} {{vehicle.type.name}}</h6>
                    <a *ngIf="overlayData.item.vehicle && overlayData.item.vehicle.id === vehicle.id">
                        <i class="fa fa-check text-success"></i>
                    </a>
                </div>
                <small>
                    Max Pallets: {{vehicle.maxPallets}} /
                    Max Weight: {{vehicle.maxWeight}} tonnes
                </small>
            </div>

<!--            <li -->
<!--                (click)="setVehicle(vehicle)"-->
<!--                *ngFor="let vehicle of vehicles"-->
<!--                class="list-group-item d-flex justify-content-between align-items-center">-->
<!--                {{vehicle.registration}} {{vehicle.type.name}}-->
<!--                <p>-->
<!--                    {{vehicle.maxWeight}} {{vehicle.maxPallets}}-->
<!--                </p>-->
<!--                <a *ngIf="overlayData.item.vehicle.id === vehicle.id">-->
<!--                    <i class="fa fa-check text-success"></i>-->
<!--                </a>-->
<!--            </li>-->
        </div>
    </div>
</div>

<app-confirmation
    *ngIf="this.confirmationData.open"
    [data]="confirmationData"
    (complete)="confirmationComplete($event)"></app-confirmation>
