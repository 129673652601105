<div class="container-fluid" style="min-height: 110vh; padding: 50px; margin-left: 252px; width: calc(100% - 252px)" *ngIf="user">

    <div class="row">
        <div class="col-12">
            <div class="preview-box">
                <div class="preview-box-title bg-grey p-4 pb-0">
                    Orders
                </div>
                <div class="preview-box-content p-4">
                    <div class="data-container">
                        <app-order-detail-search
                                class="mt-4"
                                [params]="orderParams"
                                [showSupplier]="showSupplier"
                                [showCustomer]="showCustomer"></app-order-detail-search>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>

