<div class="preview-box">
    <div class="preview-box-title bg-grey p-4 pb-0">
        Products
    </div>
    <div class="preview-box-content p-4">
            <div class="table-responsive">
            <table class="table table-condensed">
                <thead class="thead-white">

                <tr>
                    <th>Code</th>
                    <th>Product</th>
                    <th>Type</th>
                    <th>Range</th>
                    <th *ngIf="showSupplier">Supplier</th>
                    <th>Margin</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr class="underline" *ngFor="let s of products">
                    <td>{{s.productCode}}</td>
                    <td>{{s.name}}</td>
                    <td>
                        <span *ngFor="let pg of s.productGroups; let first = first"><span *ngIf="!first">, </span>{{pg.name}}</span>
                    </td>
                    <td>
                        <span *ngFor="let pg of s.productRanges; let first = first"><span *ngIf="!first">, </span>{{pg.name}}</span>
                    </td>
                    <td *ngIf="showSupplier">{{s.company.name}}</td>
                    <td>{{s.margin}}%</td>
                    <td><a [routerLink]="['/account', 'products', 'edit', s.slug]">
                        <i class="fa fa-edit"></i>
                    </a> </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="no-results" *ngIf="!products.length">
            <p>No products found</p>
        </div>

        <app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>

    </div>
</div>
