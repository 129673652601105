<div class="mail-box-header">
    <a (click)="back()"
       class="btn btn-primary compose-mail mb-2"><i class="fa fa-chevron-left"></i> Back</a>
    <span class="ml-3" style="font-size: 22px">Subject: {{group.name}}</span>

    <div class="float-right tooltip-demo">
        <a (click)="reply()" class="btn btn-mail-white btn-sm" data-toggle="tooltip" data-placement="top" title="Reply"><i class="fa fa-reply"></i> Reply</a>
<!--        <a href="#" class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Print email"><i class="fa fa-print"></i> </a>-->
<!--        <a href="mailbox.html" class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Move to trash"><i class="fa fa-trash-o"></i> </a>-->

    </div>
    <p class="mb-0">
        <span class="font-normal">From:
            <span *ngIf="group.isSender">You</span>
            <span *ngIf="!group.isSender">{{group.sender.contactName}}</span>
        </span>
        <br />
        <span class="font-normal">To: </span>
        <span *ngFor="let chatUser of group.users; let first = first"><span *ngIf="!first">, </span>{{chatUser.contactName}}</span>
        <a (click)="searchUsers()"
           class="label label-warning ml-2">
            add people
        </a>

    </p>
</div>

<div class="mail-box transparent">

    <div class="mail-body" style="background-color: white;" [ngClass]="{'hidden' : !replying}">
<!--        <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="message.content" (keyup.enter)="send()"></ckeditor>-->
        <editor [(ngModel)]="message.content" apiKey="88ar931n5931tb0bgopl9jai12nrhu3gk3edqhxl0sp5to0e" [init]="tinyInit"  (keyup.enter)="send()"></editor>

        <div class="mt-2 text-right">
            <button type="button" class="btn btn-primary btn-round" (click)="send()">Send</button>
        </div>

    </div>

<!--    <div class="mail-body mt-1 no-border" style="background-color: white;" *ngFor="let message of sortBy('createdAt')">-->
<!--        <h5>-->
<!--            <span class="float-right font-normal">{{message.createdAt | date: 'HH:mm, dd MMM'}}</span>-->
<!--            <span class="font-normal" *ngIf="messages.length > 1">From:-->
<!--                <span *ngIf="message.user.id === user.id">You</span>-->
<!--                <span *ngIf="message.user.id !== user.id">{{message.user.name}}</span>-->
<!--            </span>-->
<!--        </h5>-->
<!--        <p class="mb-0" [innerHTML]="message.content"></p>-->
<!--    </div>-->


    <div class="activity-stream" style="background-color: white;" *ngFor="let message of messages">
        <div class="stream">
            <div *ngIf="message.user.id !== user.id" class="stream-badge">
                <i class="fa fa-arrow-left bg-received"></i>
            </div>
            <div *ngIf="message.user.id === user.id" class="stream-badge">
                <i class="fa fa-arrow-right bg-sent"></i>
            </div>
            <div class="stream-panel">
                <div class="stream-info">
                    <span class="font-normal" *ngIf="messages.length > 1">
                        <span *ngIf="message.user.id === user.id">You</span>
                        <span *ngIf="message.user.id !== user.id">{{message.user.name}}</span>
                    </span>

                    <span class="date ml-4">{{message.createdAt | date: 'HH:mm, dd MMM'}}</span>
                </div>
                <p class="mb-0" style="font-size: 14px" [innerHTML]="message.content"></p>
            </div>
        </div>

    </div>





    <div class="mail-body text-right tooltip-demo">
        <a class="btn btn-sm btn-mail-white" (click)="reply()"><i class="fa fa-reply"></i> Reply</a>
<!--        <a class="btn btn-sm btn-white" href="mail_compose.html"><i class="fa fa-arrow-right"></i> Forward</a>-->
<!--        <button title="" data-placement="top" data-toggle="tooltip" type="button" data-original-title="Print" class="btn btn-sm btn-white"><i class="fa fa-print"></i> Print</button>-->
<!--        <button title="" data-placement="top" data-toggle="tooltip" data-original-title="Trash" class="btn btn-sm btn-white"><i class="fa fa-trash-o"></i> Remove</button>-->
    </div>
    <div class="clearfix"></div>


</div>

<app-mail-user-search
    [usersSelected]="group.users"
    (addUser)="addUser($event)"
    (removeUser)="removeUser($event)"
    *ngIf="(overlays$ | async).mailUserSearch"></app-mail-user-search>
