<div class="container-fluid bg-grey">
    <div class="row">
        <div class="col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-4 text-center">
            <p class="main-heading mt-5 mb-60">Reset Password</p>
            <p>Enter your new password and we'll update our records. You will then be able to log into your account.</p>
            <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
                <div class="form-group mt-60">
                    <input
                        type="password"
                        class="form-control"
                        [ngClass]="{'is-invalid' : form.controls.password.invalid && form.controls.password.dirty}"
                        formControlName="password"
                        placeholder="New password">
                </div>

                <div class="form-group">
                    <input
                        type="password"
                        class="form-control"
                        [ngClass]="{'is-invalid' : form.controls.passwordConfirm.invalid && form.controls.passwordConfirm.dirty}"
                        formControlName="passwordConfirm"
                        placeholder="Confirm new password">
                    <div class="invalid-feedback">Passwords do not match</div>
                </div>

                <div class="text-center mb-60">
                    <button
                        [disabled]="form.invalid"
                        type="submit"
                        class="btn btn-round btn-primary">
                        Reset password
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
