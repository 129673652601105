<div class="table-responsive">
    <table class="table table-condensed">
        <thead class="thead-white">
        <tr>
            <th style="width:10%;">Requested</th>
            <th style="width:12%;">Shipment No</th>
            <th>Customer</th>
            <th>Supplier</th>
            <th>Route</th>
            <th style="width:15%;">Weight / Pallets</th>
            <th>Vehicle</th>
            <th style="width:15%;">Confirmed<br />Collection Date</th>
            <th style="width:15%;">Delivery Date</th>
            <th width="">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr class="underline" *ngFor="let s of items">
            <td>{{s.createdAt | date: 'dd MMM yyyy'}}</td>
            <td>{{s.publicId}}</td>
            <td>{{s.contactsName}}</td>
            <td style="width:12%">{{s.supplierName}}</td>
            <td style="width:10%">{{s.collectionPostcodeUsed}} <i class="fa fa-arrow-right"></i> {{s.deliveryPostcodeUsed}}</td>
            <td>{{s.weight}} /{{s.pallets}}</td>
            <td style="width:12%;">
                <ng-container *ngIf="s.vehicle">
                    {{s.vehicle.registration}}
                </ng-container>
                <ng-container *ngIf="!s.vehicle">
                    Not assigned
                </ng-container>
                <a (click)="assignVehicle(s)">
                    <i class="fa fa-edit"></i>
                </a>
            </td>
            <td style="width:12%;">
                <p *ngIf="s.collectionDate">{{s.collectionDate | date: 'dd MMM'}}</p>
            </td>

            <td>
                <p *ngIf="!s.confirmedShippingDate">Est. {{s.estShippingDate | date: 'dd MMM'}}</p>
                <p *ngIf="s.confirmedShippingDate">{{s.confirmedShippingDate | date: 'dd MMM'}}</p>
            </td>
            <td>
                <p *ngIf="s.status.id < 5">In production</p>
                <p *ngIf="s.status.id === 5">Awaiting collection</p>
                <p *ngIf="s.status.id === 6">In transit</p>
                <p *ngIf="s.status.id === 7">Delivered</p>
            </td>
            <td><a [routerLink]="['/account', 'shipments', s.id]">
                <i class="fa fa-search"></i>
            </a> </td>
        </tr>
        </tbody>
    </table>
</div>

<div class="no-results" *ngIf="!items.length">
    <p>No shipments found</p>
</div>

<app-pagination *ngIf="params.pageArray.length" [params]="params" (update)="search()"></app-pagination>

<app-shipment-vehicle
    *ngIf="(overlays$ | async).shipmentVehicle"
    [overlayData]="overlayData"
    (refreshData)="this.filterSearch()"></app-shipment-vehicle>

<app-confirmation
    *ngIf="confirmationData.open"
    [data]="confirmationData"
    (complete)="confirmationComplete($event)"></app-confirmation>
